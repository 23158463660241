import React, { useState } from "react";
import { IoIosShareAlt, IoMdClose, IoMdLink } from "react-icons/io";
import {
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ReactStars from "react-rating-stars-component";

const CustomCallbackPopup1 = ({
  onClose,
  onRequestCallback,
  onShareClick,
  userName,
  userMobileNumber,
}) => {
  const [isRated, setIsRated] = useState(false);

  const handleRequestCallback = () => {
    if (!isRated) {
      onRequestCallback();
      setIsRated(true);
    }
  };

  const shareOnSocialMedia = (platform) => {
    const shareUrl = "https://ninereflex.xrvizion.com/";
    const message = `https://ninereflex.xrvizion.com/ 🏡✨ "HOME IS WHERE YOUR STORY BEGINS!" ✨🏡  

Discover NINE REFLEX – smart 2BHK homes designed for modern living! 🏠💡 Featuring stylish architecture, home automation, energy-efficient appliances, and intelligent security, these homes blend luxury and functionality seamlessly.  

🌟 Experience the future with our AI-powered walkthrough by XRVIZION! 🚀🤖  

Visit today and step into your dream home! 🌟`;

    let url;
    switch (platform) {
      case "instagram":
        url = `https://www.instagram.com/?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(message)}`;
        break;
      case "whatsapp":
        url = `https://wa.me/?text=${encodeURIComponent(message)}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(message)}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "share":
        if (navigator.share) {
          navigator
            .share({
              title: document.title,
              text: message,
              url: shareUrl,
            })
            .catch((err) => console.error(err));
        }
        break;
      default:
        break;
    }
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div style={styles.customPopup} className="dropdown-cont2">
      <div style={styles.customPopupContent}>
        <IoMdClose style={styles.closeIcon} onClick={onClose} />
        <p style={styles.headerText}>Rate your experience</p>
        <ReactStars
          count={5}
          onChange={handleRequestCallback}
          size={29}
          activeColor="#f5f5dc"
          inactiveColor="rgba(255, 255, 255, 0.2)"
          color="rgba(255, 255, 255, 0.2)"
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          isHalf={false}
          edit={!isRated} // Disable editing after rating
        />
        <p style={styles.sloganText}>
          Feeling the magic? Share your experience now!
        </p>

        <div style={styles.iconContainer}>
          <FaInstagram
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("instagram")}
          />
          <FaWhatsapp
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("whatsapp")}
          />
          <FaFacebook
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("facebook")}
          />
          <FaXTwitter
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("twitter")}
          />
          <FaLinkedin
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("linkedin")}
          />
          <IoMdLink
            style={styles.shareIcon}
            onClick={() => shareOnSocialMedia("share")}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  customPopup: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.73) !important",
    padding: "10px",
    borderRadius: "7px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    zIndex: "10000",
    textAlign: "center",
    width: "80%",
    maxWidth: "20rem",
    fontFamily: "Clash Display Regular",
    fontSize: "0.8rem",
    height: "auto",
  },
  customPopupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.72) !important",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
  },
  headerText: {
    fontSize: "1rem",
    marginBottom: "6px",
  },
  iconContainer: {
    marginTop: "5px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    marginBottom: "10px",
  },
  shareIcon: {
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: "5px",
    transition: "transform 0.3s ease, background-color 0.3s ease",
  },
  shareIconHover: {
    transform: "scale(1.2)",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  sloganText: {
    fontSize: "1rem",
    color: "white",
    marginTop: "5px",
    paddingLeft: "0.6rem",
    paddingRight: "0.6rem",
  },
};

export default CustomCallbackPopup1;
