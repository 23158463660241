import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import axios from "axios"; // Import axios

const FeedbackForm = ({ }) => {
  const [rating, setRating] = useState(null);
  const [userName, setUserName] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [description, setDescription] = useState("");
  const [isRated, setIsRated] = useState(false);

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const initialOrientation =
    isMobile && window.matchMedia("(orientation: landscape)").matches
      ? "landscape"
      : "other";

  const [orientation, setOrientation] = useState(initialOrientation);
  const url = "https://api.ninereflex.xrvizion.com";

  // ========================================login again=======================================
  const handleLoginAgain = () => {
    const logoutTime = new Date(localStorage.getItem("logoutTime"));
    const now = new Date();
    const timeDifference = (now - logoutTime) / 1000;

    if (timeDifference < 60) {
      window.location.href = "/";
    } else {
      window.location.href = "/login";
    }
  };

  // ========================================feedback request=======================================
  const handleSubmit = async () => {
    const feedbackData = {
      name: userName,
      mobileNumber: userMobileNumber,
      feedback: description,
    };

    try {
      const response = await axios.post(
        `${url}/user/setfeedback`,
        feedbackData
      );
      console.log("Feedback submitted:", response.data);
      toast.success("Feedback submitted successfully!", {
        position: window.innerWidth > 768 ? "top-right" : "bottom-center",
        className: window.innerWidth < 768 && "fs-sm",
        style: {
          background:
            "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
        },
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Error submitting feedback", {
        position: window.innerWidth > 768 ? "top-right" : "bottom-center",
        className: window.innerWidth < 768 && "fs-sm",
        style: {
          background:
            "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
        },
      });
    }
  };

  // ========================================rating========================================================
  const handleRating = async (newRating) => {
    setRating(newRating);
    try {
      const response = await axios.post(`${url}/user/setrating`, {
        name: userName,
        mobileNumber: userMobileNumber,
        rating: newRating,
      });
      console.log("Rating submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  // =========================================Retrieve details from local storage=======================================
  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    const storedMobileNumber = localStorage.getItem('userMobileNumber');

    setUserName(storedUserName || '');
    setUserMobileNumber(storedMobileNumber || '');
  }, []);

  // =========================================Retrieve details from local storage and fetch rating and feedback=======================================
  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    const storedMobileNumber = localStorage.getItem('userMobileNumber');

    setUserName(storedUserName || '');
    setUserMobileNumber(storedMobileNumber || '');

    const fetchRatingAndFeedback = async () => {
      try {
        const response = await axios.post(`${url}/user/getfeedbackandrating`, {
          name: storedUserName,
          mobileNumber: storedMobileNumber,
        });

        console.log("Rating response:", response.data);  // Log the exact response
        const ratingValue = response.data.rating;

        // Force state update
        setRating(null);  // Reset first
        setTimeout(() => setRating(ratingValue), 0);
      } catch (error) {
        console.error("Error fetching rating and feedback:", error);
        setRating(0);
      }
    };

    fetchRatingAndFeedback();
  }, []);

  const isButtonDisabled = description.length < 1;

  return (
    <div style={styles.container}>
      <div style={styles.customPopupContent}>
        <p style={styles.headerText}>
          How was your experience with our AI Agent Demo?
        </p>

        <div style={styles.starContainer}>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              style={{
                color: star <= rating ? "#f5f5dc" : "rgba(255, 255, 255, 0.2)",
                fontSize: "40px",
                cursor: "pointer",
              }}
              onClick={() => handleRating(star)}
              onMouseEnter={() => setRating(star)}
              onMouseLeave={() => setRating(rating)}
            >
              &#9733;
            </span>
          ))}
        </div>

        <div style={styles.suggestionContainer}>
          <p style={styles.suggestionText}>
            Do you have any suggestions for improvements?
          </p>
          <textarea
            className=""
            style={styles.descriptionBox}
            placeholder="Tell us more about your experience"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div style={styles.actions}>
          <div style={styles.buttonContainer}>
            <button
              className="start-button ff-sb"
              style={isButtonDisabled ? styles.buttonDisabled : styles.button}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              Send Feedback <MdOutlineArrowRightAlt style={styles.arrowIcon} />
            </button>
          </div>
          <div style={styles.buttonContainer}>
            <button style={styles.loginButton} onClick={handleLoginAgain}>
              Login again
            </button>
          </div>
          <div>
            <div className="ff-l fs-xs" style={styles.poweredBy}>
              powered by
            </div>
            <img
              className="xrv-logo-img-new"
              src="https://d3f67ez5tfvtll.cloudfront.net/XRVizion/xrv.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.72)",
    margin: 0,
    padding: 0,
  },
  customPopupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.72)",
    padding: "20px",
    borderRadius: "7px",
    textAlign: "center",
    color: "#f5f5dc",
    width: "80%",
    maxWidth: "20rem",
    fontFamily: "Clash Display Regular",
    fontSize: "0.8rem",
    border: "1px solid rgb(56, 54, 54)",
  },
  headerText: {
    fontSize: "1rem",
    marginBottom: "6px",
  },
  starContainer: {
    display: "flex",
    gap: "5px",
    marginBottom: "20px",
  },
  suggestionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2px",
  },
  suggestionText: {
    fontSize: "0.8rem",
    marginBottom: "3px",
    marginLeft: "5px",
  },
  descriptionBox: {
    height: "100px",
    WebkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "hsla(0,0%,100%, .087)",
    border: "none",
    borderRadius: "0.5rem",
    color: "beige",
    marginBottom: "0.35rem",
    outline: "none",
    textIndent: "0.25rem",
    width: "100%",
    margin: "0.5rem 0rem !important",
    padding: "0.5rem 0.65rem !important",
    boxSizing: "border-box",
    fontSize: "1rem",
    fontFamily: "Clash Display Regular",
  },
  actions: {
    marginTop: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  button: {
    backgroundColor: "#f5f5dc",
    border: "none",
    borderRadius: "5px",
    color: "#000",
    padding: "10px 20px",
    margin: "5px",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  buttonDisabled: {
    backgroundColor: "#d3d3d3",
    border: "none",
    borderRadius: "5px",
    color: "#000",
    padding: "10px 20px",
    margin: "5px",
    cursor: "not-allowed",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  arrowIcon: {
    fontSize: "24px",
    marginLeft: "5px",
  },
  loginButton: {
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    textDecoration: "underline",
    fontFamily: "Clash Display Regular",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  poweredBy: {
    marginTop: "3rem",
    fontStyle: "italic",
    marginBottom: "0.3rem",
  },
};

export default FeedbackForm;
