import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import ReactPlayer from "react-player";
import Navigation from "./Assets/Navigation";
import ConfigurationPopup from "./Assets/config";
import { BsCurrencyDollar } from "react-icons/bs";
import { GoHeartFill } from "react-icons/go";
import CustomCallbackPopup1 from "./Assets/custom";
import CustomCallbackPopup from "./Assets/customCall";
import toast, { Toaster } from "react-hot-toast";

import { IoShareSocial } from "react-icons/io5";
import InactivityLogout from "./Assets/Utils/InactivityLogout";
import anime from "animejs";

import { preloadedMedia } from "./Assets/Utils/MediaPreloader";

import LocationPopup from "./Assets/location";
import TextToSpeech from "./Assets/TextToSpeech";
import mediaDictionary from "./Assets/KeywordMediaMap";
import SiteBooking from "./Assets/SiteBooking";
import DayDatePicker from "./Assets/DayDatePicker";

import io from "socket.io-client";
import { BeatLoader } from "react-spinners";
import BotHandler from "./BotHandler";
import { VscSend } from "react-icons/vsc";
import { PiMicrophoneFill, PiMicrophone } from "react-icons/pi";
import LivingAreaData from "./Assets/VisemeData/LivingAreaData";
import PreQuestions from "./Assets/PreQuestions";
import PanoramaViewer from "./Assets/PanoramaViewer";

import {
  FaInstagram,
  FaXTwitter,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { CgClose } from "react-icons/cg";

import { BsInfoLg } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import FilterPopup from "./Assets/costSheet";
import { useLanguage } from "./Assets/LanguageContext";

import TypingAnimation from "./Assets/Utils/TypingAnimation";

const sampleRate = 16000;
let stream;
const getMediaStream = async () => {
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: "default",
        sampleRate: sampleRate,
        sampleSize: 16,
        channelCount: 1,
      },
      video: false,
    });
    return stream;
  } catch (error) {
    console.error("Error accessing media stream:", error);
  }
};

const AudioToText = (props) => {
  const {
    userMobileNumber,
    setUserMobileNumber,
    isListening,
    setIsListening,
    isTalking,
    setIsTalking,
    setTalkAnimation,
    startClicked,
    setIsThinking,
    isThinking,
    setAudioSrc,
    isMuted,
    setIsMuted,
    setIsPlaying,
    setStartClicked,
    navigationProp,
    isAgent,
    userName,
  } = props;
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [visemeData, setVisemeData] = useState();
  // const [language, setLanguage] = useState("EN");
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [showConfigurationPopup, setShowConfigurationPopup] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [costSheetData, setCostSheetData] = useState(null);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const generateTTS = useRef();
  const [customPopupTimer, setCustomPopupTimer] = useState(null);
  const [isInterrupted, setIsInterrupted] = useState(false);
  const [showSiteBooking, setShowSiteBooking] = useState(false);
  const [showSiteBookingForm, setShowSiteBookingForm] = useState(false);
  const showLocationPopupTrigger = useRef(false);
  const showConfigurationPopupTrigger = useRef(false);
  const showFilterPopupTrigger = useRef(false);
  const { language, setLanguage } = useLanguage(); // Use the useLanguage hook
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const chatIndex = useRef(0);

  const [visemeResponse, setVisemeResponse] = useState(false);
  const [inputMsg, setInputMsg] = useState("");
  const inputRef = useRef(null);

  const showSiteBookingTrigger = useRef(false);
  const [isLiked, setIsLiked] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const isFollowupResponse = useRef(false);
  const hasReceivedSessionId = useRef(false); // Track if we've ever received a session ID

  const datePickerRef = useRef();
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(false);
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);
  const [currentPanorama, setCurrentPanorama] = useState(
    navigationProp.mediaList[navigationProp.playIndex]
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [textToSpeechResponse, setTextToSpeechResponse] = useState();
  const [showSubItems, setShowSubItems] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [currentSubItem, setCurrentSubItem] = useState("");
  const [playIndex, setPlayIndex] = useState(0);

  const [keywordsToPlay, setKeywordsToPlay] = useState([]);

  const restrictBotResponse = useRef(false);

  const [mediaList, setMediaList] = useState([
    "livingroom.jpg",
    "kitchen.jpg",
    "bedroom.jpg",
    "diningroom.jpg",
  ]);
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  function checkFileType(url) {
    if (!url) return;
    const imageExtensions = ["jpg", "png"];
    const videoExtensions = ["mp4"];
    const fileExtension = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
    if (imageExtensions.includes(fileExtension)) {
      return "image";
    } else if (videoExtensions.includes(fileExtension)) {
      return "video";
    } else {
      return "unknown";
    }
  }

  const sendLocationToBackend = async (
    mobileNumber,
    name,
    location,
    sessionId
  ) => {
    try {
      const response = await axios.post(`${url}/user/setuserlocation`, {
        mobileNumber,
        name,
        location,
        sessionId,
      });
      console.log("Location data sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending location data:", error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.type === "beforeunload") {
        event.preventDefault();
        event.returnValue = "";
        setShowCustomPopup(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const timeoutId = setTimeout(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }, 57000); // 55 seconds

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearTimeout(timeoutId);
    };
  }, []);



  const sendConfigurationToBackend = async (
    mobileNumber,
    name,
    configuration,
    sessionId
  ) => {
    try {
      const response = await axios.post(`${url}/user/setuserconfiguration`, {
        mobileNumber,
        name,
        configuration,
        sessionId,
      });
      console.log("Configuration data sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending configuration data:", error);
    }
  };
  const handleLikeClick = async () => {
    try {
      const response = await axios.post(`${url}/user/setlike`, {
        name: userName.current,
        mobileNumber: userMobileNumber.current,
        // sessionId: session.current
      });
      console.log("Like data sent:", response.data);
      setIsLiked((prevIsLiked) => !prevIsLiked); // Toggle the like state
    } catch (error) {
      console.error("Error sending like data:", error);
    }
  };

  // useLeavePageConfirmation(showCustomPopup, setShowCustomPopup);

  const handleRequestCallback = async () => {
    try {
      const response = await axios.post(`${url}/user/setCallback`, {
        name: userName.current,
        mobileNumber: userMobileNumber.current,
      });
      console.log("Callback data sent:", response.data);
    } catch (error) {
      console.error("Error sending callback data:", error);
    }
  };

  const handleCallbackClick = async () => {
    try {
      const response = await axios.post(`${url}/user/setCallback`, {
        name: userName.current,
        mobileNumber: userMobileNumber.current,
        date: new Date().toISOString(), // Include the date field with the current date and time
      });
      console.log("Callback request successful:", response.data);
      setShowCustomPopup(false);
    } catch (error) {
      console.error("Error requesting callback:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const [locationSent, setLocationSent] = useState(false);
  const [configurationSent, setConfigurationSent] = useState(false);

  const handleFilterSelected = async (floor, size) => {
    setSelectedFloor(floor);
    setSelectedSize(size);
    setIsThinking(true); // Set thinking state to true

    try {
      const response = await axios.post(`${url}/user/calculatecostsheet`, {
        floor: floor,
        size: size,
      });

      // Store the response data
      setCostSheetData(response.data);

      // You can handle the response data here
      console.log("Cost sheet data:", response.data);
    } catch (error) {
      console.error("Error calculating cost sheet:", error);
      // Handle error appropriately
    } finally {
      setIsThinking(false); // Reset thinking state to false
    }
  };

  const getAudioDuration = (audioUrl) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(audioUrl);
      audio.addEventListener("loadedmetadata", () => {
        resolve(audio.duration);
      });
      audio.addEventListener("error", (err) => {
        reject(err);
      });
    });
  };

  const shouldPlayResponse = useRef(false);
  const [textToSpeak, setTextToSpeak] = useState("");
  const [connection, setConnection] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [currentRecognition, setCurrentRecognition] = useState("");
  const processorRef = useRef();
  const audioContextRef = useRef();
  const audioInputRef = useRef();
  const [buttonText, setButtonText] = useState("Ask me");
  const [startingToRecord, setStartingToRecord] = useState(false);
  const [responseReady, setResponseReady] = useState(true);
  const recordingStoppedByTimerRef = useRef(false);
  const [chatMessages, setChatMessages] = useState([]);

  const createMessage = (content, from) => ({ content, from });
  const appendMessage = (messages, content, from) => {
    const newMessage = createMessage(content, from);
    return [...messages, newMessage];
  };

  const session = useRef(0);
  const url = "https://api.ninereflex.xrvizion.com";
  const menuItemsToDisplay = useRef([]);
  const [toggleAIChat, setToggleAIChat] = useState(true);

  useEffect(() => {
    const container = document.querySelector(".ui-container");
    const navMenu = document.querySelector(".nav-menu");
    const playBtn = document.querySelector(".play-btn-cont");
    const toggleButton = document.querySelector(".divider");
    if (toggleAIChat) {
      container.classList.add("show");
      container.classList.remove("hide");
      setTimeout(() => {
        navMenu.classList.add("show");
        navMenu.classList.remove("hide");
        playBtn.classList.add("show");
        playBtn.classList.remove("hide");
        toggleButton.classList.add("show");
        toggleButton.classList.remove("hide");
      }, 100);
    } else {
      container.classList.add("hide");
      container.classList.remove("show");
      toggleButton.classList.add("hide");
      toggleButton.classList.remove("show");
      setTimeout(() => {
        navMenu.classList.add("hide");
        navMenu.classList.remove("show");
        playBtn.classList.add("hide");
        playBtn.classList.remove("show");
      }, 200);
    }
  }, [toggleAIChat]);

  const audioRef = useRef(new Audio());
  const audioRefAlt = useRef(new Audio());
  const audioRefAlt2 = useRef(new Audio());

  const handleVolume = () => {
    setIsMuted((prev) => !prev);
  };

  const shouldErrorMsgPlay = useRef(true);
  const shouldRecordAgain = useRef(false);
  let playPromise;

  const stopAudio = () => {
    setIsTalking(false);
    setIsThinking(false);
  };

  useEffect(() => {
    if (isRecording) {
      setIsListening(true);
    } else {
      setIsListening(false);
    }
  }, [isRecording]);

  useEffect(() => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
  }, [startClicked]);

  useEffect(() => {
    const handlePlay = () => {
      if (isTalking) {
        const animations = ["Talking_1", "Talking_2", "Talking_3"];
        const animation =
          animations[Math.floor(Math.random() * animations.length)];
        setTalkAnimation(animation);
      }
    };
    audioRef.current.addEventListener("play", handlePlay);
    const handleEnded = () => {
      setIsTalking(false);
      if (shouldRecordAgain.current) {
      }
    };
    audioRef.current.addEventListener("ended", handleEnded);
    return () => {
      audioRef.current.removeEventListener("play", handlePlay);
      audioRef.current.removeEventListener("ended", handleEnded);
    };
  }, [isTalking, setIsTalking, setTalkAnimation]);

  const newResponseMsg = useRef("");
  const [updateMedia, setUpdateMedia] = useState("");

  useEffect(() => {
    if (isRecording) {
      setIsListening(true);
    } else {
      setIsListening(false);
    }
  }, [isRecording]);

  useEffect(() => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
  }, [startClicked]);

  useEffect(() => {
    const handlePlay = () => {
      if (isTalking) {
        const animations = ["Talking_1", "Talking_2", "Talking_3"];
        const animation =
          animations[Math.floor(Math.random() * animations.length)];
        setTalkAnimation(animation);
      }
    };
    audioRef.current.addEventListener("play", handlePlay);
    const handleEnded = () => {
      setIsTalking(false);
      if (shouldRecordAgain.current) {
      }
    };
    audioRef.current.addEventListener("ended", handleEnded);
    return () => {
      audioRef.current.removeEventListener("play", handlePlay);
      audioRef.current.removeEventListener("ended", handleEnded);
    };
  }, [isTalking, setIsTalking, setTalkAnimation]);

  const playAudio = async (audioUrl, lipsyncData) => {
    setCurrentRecognition(newResponseMsg.current);

    if (menuItemsToDisplay.current.length) {
      const mainMenuName = Object.keys(navigationProp.submenuItems).find(
        (menu) =>
          navigationProp.submenuItems[menu].includes(
            menuItemsToDisplay.current[0]
          )
      );
      if (mainMenuName) {
        navigationProp.setActiveSubMenu(mainMenuName);
        setUpdateMedia(menuItemsToDisplay.current[0]);
      }
    }

    setIsTalking(true);
    setIsThinking(false);
    setResponseReady(true);
    shouldErrorMsgPlay.current = false;
    restrictBotResponse.current = false;

    try {
      const duration = await getAudioDuration(audioUrl);
      console.log("Audio duration:", duration);

      if (window.popupTimeout) {
        clearTimeout(window.popupTimeout);
      }

      window.popupTimeout = setTimeout(() => {
        if (showLocationPopupTrigger.current) {
          setShowLocationPopup(true);
          showLocationPopupTrigger.current = false;
        } else if (showConfigurationPopupTrigger.current) {
          setShowConfigurationPopup(true);
          showConfigurationPopupTrigger.current = false;
        } else if (showFilterPopupTrigger.current) {
          setShowFilterPopup(true);
          showFilterPopupTrigger.current = false;
        } else if (showSiteBookingTrigger.current) {
          setShowSiteBooking(true);
          showSiteBookingTrigger.current = false;
        }
        restrictBotResponse.current = false;
      }, duration * 1000);
    } catch (error) {
      console.error("Error getting audio duration:", error);
    }
  };
  
  useEffect(() => {
    const socket = io.connect(url);
    let pingInterval;

    socket.on("connect", () => {
      setConnection(socket);
      pingInterval = setInterval(sendPingMessage, 5000);
    });

    generateTTS.current = (text) => {
      socket.emit(
        "visemes",
        text,
        language,
        userName.current,
        userMobileNumber.current,
        session.current === 0 ? true : false,
        session.current
      );
    };

    socket.on("visemeresponse", (data) => {
      console.log("visemeresponse", data);
      shouldPlayResponse.current = true;
      setTextToSpeechResponse(data.ttsResponse);
      setTextToSpeak(data.text); // Changed from newResponseMsg.current to data.text
      setVisemeData(data.visemes);
      setIsTalking(false);

      // Always append viseme response message
      setChatMessages((prevMessages) =>
        appendMessage(prevMessages, data.text, "bot")
      );

      console.log("Visemes set:", data.visemes);
    });

    const sendPingMessage = () => {
      socket.emit("ping", userMobileNumber.current, userName.current);
    };

    socket.on("completedResponse", (data) => {
      // Handle completed response if needed
    });

    socket.on("chatresponse", async (data) => {
      console.log("data", data);
      setIsTalking(false);

      if (restrictBotResponse.current) return;

      menuItemsToDisplay.current = [];

      if (data.keywords) {
        menuItemsToDisplay.current = data.keywords;
        const audioBlob = new Blob([data.ttsResponse], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const duration = await getAudioDuration(audioUrl);
        navigationProp.setAudioDuration(duration);
        setKeywordsToPlay(data.keywords);
      }

      newResponseMsg.current = data.completedText;

      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }

      shouldPlayResponse.current = true;
      if (data.ttsResponse) setTextToSpeechResponse(data.ttsResponse);
      if (data.completedText) setTextToSpeak(data.completedText);
      if (data.visemes) setVisemeData(data.visemes);
      if (data.newSession) {
        session.current = data.sessionId;
      }

      setChatMessages((prevMessages) =>
        appendMessage(prevMessages, data.completedText, "bot")
      );

      // Debug log to verify visemes are set
      console.log("Visemes set in chatresponse:", data.visemes);

      if (
        data.completedText.includes("location") ||
        data.completedText.includes("configuration")
      ) {
        setVisemeData(data.visemes);
      }
    });

    socket.on("serverError", async () => {
      if (shouldErrorMsgPlay.current) {
        console.log("");

        await revokeMicrophoneAccess();

        let botMsg =
          language === "hi-IN"
            ? "क्षमा करें मैं आपको समझ नहीं पाई, क्या आप इसे दोबारा कह सकते हैं?"
            : language === "MA"
            ? "माफ करा मला ते समजले नाही, तुम्ही ते पुन्हा सांगू शकता का?"
            : "Sorry, I didn't get that. Can you say it again?";
        newResponseMsg.current = botMsg;
        generateTTS.current(botMsg);
      }

      console.log("isThinking setting to false");

      setResponseReady(true);
      setIsThinking(false);
      shouldRecordAgain.current = false;
      shouldErrorMsgPlay.current = true;

      // Reset the bot's state to allow new inputs
      setIsRecording(false);
      setCurrentRecognition("");
      setButtonText("Ask me");

      // Clear any pending timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      console.log("Chat messages after server error:", chatMessages);
    });

    socket.on("disconnect", () => {
      handleLogout();
    });

    socket.on("final", async () => {
      if (recordingStoppedByTimerRef.current === false) {
        clearTimeout(timeoutRef.current);

        if (connection) {
          connection.emit("endStream");
          console.warn("Sending END STREAM from Final");
        }

        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (
          audioContextRef.current &&
          audioContextRef.current.state !== "closed"
        ) {
          audioContextRef.current.close();
        }

        await revokeMicrophoneAccess();
        setAudioSrc(
          "https://d3f67ez5tfvtll.cloudfront.net/Butati/Resources/stop-13692.mp3"
        );
        setIsPlaying(true);

        setIsRecording(false);
        setResponseReady(false);
        console.log("running final");
        setIsThinking(true);
      }
    });

    socket.on("Invoke Site Visit", () => {
      console.log("Backend triggered: InvokeBookingDate");
      showSiteBookingTrigger.current = true;
      console.log(
        "Site booking trigger set to:",
        showSiteBookingTrigger.current
      );

      // Debug check - set up a short interval to monitor the trigger value
      const debugInterval = setInterval(() => {
        console.log("Current trigger value:", showSiteBookingTrigger.current);
      }, 1000);

      // Clear the interval after 5 seconds
      setTimeout(() => clearInterval(debugInterval), 5000);
    });

    socket.on("Invoke Configuration", () => {
      console.log("Backend Triggered: Invoke Configuration");
      showConfigurationPopupTrigger.current = true;
    });
    socket.on("Invoke Location", () => {
      console.log("Backend Triggered: Invoke Location");
      if (!isInterrupted) {
        showLocationPopupTrigger.current = true;
      }
    });

    socket.on("invokecostsheet", () => {
      console.log("Backend Triggered: Invoke Cost sheet");
      showFilterPopupTrigger.current = true;
    });

    socket.on("audio_to_text", async (res) => {
      clearTimeout(timeoutRef.current);

      if (connection) {
        console.warn("Sending END STREAM from Audio-to-text");
        connection.emit("endStream");
      }
      processorRef.current?.disconnect();
      audioInputRef.current?.disconnect();
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }

      if (recordingStoppedByTimerRef.current === false) {
        await revokeMicrophoneAccess();
        setAudioSrc(
          "https://d3f67ez5tfvtll.cloudfront.net/Butati/Resources/stop-13692.mp3"
        );
        setIsPlaying(true);
      }

      setChatMessages((prevMessages) =>
        appendMessage(prevMessages, res.text, "user")
      );

      setIsRecording(false);
      setResponseReady(false);
      console.log("running");
      setIsThinking(true);
    });

    return () => {
      socket.disconnect();
      clearInterval(pingInterval);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const timerRef = useRef(null);

  useEffect(() => {
    if (!responseReady) {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setResponseReady(true);
        // setButtonText("Ask me");
        setCurrentRecognition("");
        timerRef.current = null;
      }, 100000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [responseReady]);

  // useEffect(() => {
  //   console.log(isRecording, responseReady, isThinking, startingToRecord)
  // }, [isRecording, responseReady, isThinking, startingToRecord])

  useEffect(() => {
    const recButton = document.querySelector(".ui-rec-btn");
    if (isRecording) {
      recButton?.classList.add("active");
      // setButtonText("Listening...");
      setStartingToRecord(false);
    } else {
      recButton?.classList.remove("active");
      // setButtonText("Ask me");
    }
  }, [isRecording]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!responseReady) {
      // setButtonText("Thinking...");
    } else if (isTalking) {
      // setButtonText("Talking...")
    } else if (isThinking) {
      // setButtonText("Thinking...")
    } else {
      // setButtonText("Ask me");
    }
  }, [responseReady, isTalking, isThinking]);
  const stoppedForcefully = async () => {
    recordingStoppedByTimerRef.current = true;
    if (connection) connection.emit("endStream");
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }

    await revokeMicrophoneAccess();
    setAudioSrc(
      "https://d3f67ez5tfvtll.cloudfront.net/Butati/Resources/stop-13692.mp3"
    );
    setIsPlaying(true);

    setIsRecording(false);
    setResponseReady(false);
    setIsThinking(true);
    clearTimeout(timeoutRef.current);

    // Reset popup triggers
    showLocationPopupTrigger.current = false;
    showConfigurationPopupTrigger.current = false;
    showFilterPopupTrigger.current = false;
    showSiteBookingTrigger.current = false;
  };

  const handleMicrophoneClick = () => {
    resettTimer();
    startRecording();
  };
  const handleInputFocus = () => {
    resettTimer();
  };

  const [counter, setCounter] = useState(0);
  const [phase, setPhase] = useState(1);
  // Phase 1, 2, 3
  const resettTimer = () => {
    setCounter(0);
    setPhase(1);
  };

  useEffect(() => {
    if (!connection) return;

    // Listen for visemeresponse to update session ID
    const handleVisemeResponse = (data) => {
      console.log("Received visemeresponse with session:", data.sessionId);
      if (data.sessionId) {
        session.current = data.sessionId;
        console.log("Updated session ID to:", session.current);
      }
    };

    // Set up socket listener
    connection.on("visemeresponse", handleVisemeResponse);

    // Cleanup
    return () => {
      connection.off("visemeresponse", handleVisemeResponse);
    };
  }, [connection]);

  const sessionId = useRef(null);

  useEffect(() => {
    if (!connection) return;

    const handleVisemeResponse = (data) => {
      console.log("Received visemeresponse with session:", data.sessionId);
      if (data.sessionId) {
        sessionId.current = data.sessionId;
        console.log("Updated session ID to:", sessionId.current);
      }
    };

    connection.on("visemeresponse", handleVisemeResponse);

    return () => {
      connection.off("visemeresponse", handleVisemeResponse);
    };
  }, [connection]);

  useEffect(() => {
    if (!connection) return;

    const handleSessionUpdate = (data) => {
      if (data.sessionId) {
        console.log("Received session ID:", data.sessionId);
        sessionId.current = data.sessionId;
        hasReceivedSessionId.current = true; // Mark that we've received a session ID
        console.log("Updated session ID to:", sessionId.current);
      }
    };

    // Listen for both chatresponse and visemeresponse
    connection.on("chatresponse", handleSessionUpdate);
    connection.on("visemeresponse", handleSessionUpdate);

    return () => {
      connection.off("chatresponse", handleSessionUpdate);
      connection.off("visemeresponse", handleSessionUpdate);
    };
  }, [connection]);

  useEffect(() => {
    let timer;
    const phaseTiming = 30;

    const emitFollowup = (currentPhase) => {
      if (!connection) return;

      // If we've ever received a session ID, use it - otherwise use 0
      const currentSessionId = hasReceivedSessionId.current
        ? sessionId.current
        : 0;
      const newSession = !hasReceivedSessionId.current;

      console.log(`Phase ${currentPhase} completed. Emitting followup...`);
      console.log({
        language,
        userName: userName.current,
        userMobileNumber: userMobileNumber.current,
        newSession,
        sessionId: currentSessionId,
        hasReceivedSessionId: hasReceivedSessionId.current,
      });

      connection.emit(
        "followup",
        language,
        userName.current,
        userMobileNumber.current,
        newSession,
        currentSessionId
      );
    };

    const resetTimer = () => {
      if (timer) clearInterval(timer);
      timer = null;
      setCounter(0);
      setPhase(1);
      console.log("Timer reset to phase 1");
    };

    const moveToNextPhase = () => {
      if (phase < 3) {
        if (timer) clearInterval(timer);
        timer = null;
        setCounter(0);
        setPhase((prev) => prev + 1);
        console.log(`Moving to phase ${phase + 1}`);
      }
    };

    const startTimer = () => {
      if (timer) return;

      console.log(`Starting timer for phase ${phase}`);
      timer = setInterval(() => {
        setCounter((prevCounter) => {
          const nextCount = prevCounter + 1;
          console.log(`Phase ${phase}: ${nextCount}/${phaseTiming} seconds`);

          if (nextCount >= phaseTiming) {
            emitFollowup(phase);
            moveToNextPhase();
            return 0;
          }
          return nextCount;
        });
      }, 1000);
    };

    if (!isTalking && !isThinking && !isRecording && !inputMsg.length) {
      startTimer();
    } else if (!isFollowupResponse.current) {
      resetTimer();
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [
    isTalking,
    isThinking,
    isRecording,
    inputMsg,
    phase,
    connection,
    language,
  ]);

  const startRecording = async () => {
    restrictBotResponse.current = false;

    if (showSiteBooking || showSiteBookingForm) {
      setShowSiteBooking(false);
      setShowSiteBookingForm(false);
    }
    if (inputMsg.length) {
      if (!connection) return;
      shouldRecordAgain.current = false;
      stopAudio();
      setChatMessages((prevMessages) =>
        appendMessage(prevMessages, inputMsg, "user")
      );
      let newSession = session.current === 0;
      let sessionId = newSession ? 0 : session.current;
      connection.emit(
        "startTextInput",
        language === "en-IN"
          ? "en-IN"
          : language === "hi-IN"
          ? "hi-IN"
          : language === "GU"
          ? "gu-IN"
          : language === "TA"
          ? "ta-IN"
          : language === "TE"
          ? "te-IN"
          : language === "ML"
          ? "ml-IN"
          : language === "KN"
          ? "kn-IN"
          : "en-IN", // Default fallback
        sessionId,
        inputMsg,
        userName.current,
        userMobileNumber.current,
        newSession
      );
      setInputMsg("");
      setIsThinking(true);
      setIsPlaying(false);
      return;
    }
    if (isRecording) {
      shouldRecordAgain.current = false;
      stoppedForcefully();
      return;
    }
    setStartingToRecord(true);
    shouldRecordAgain.current = true;
    recordingStoppedByTimerRef.current = false;
    stopAudio();
    setCurrentRecognition("...");
    if (!connection || isRecording) return;
    let newSession = session.current === 0;
    let sessionId = newSession ? 0 : session.current;
    connection.emit(
      "startStream",
      language === "en-IN"
        ? "en-IN"
        : language === "hi-IN"
        ? "hi-IN"
        : language === "GU"
        ? "gu-IN"
        : language === "TA"
        ? "ta-IN"
        : language === "TE"
        ? "te-IN"
        : language === "ML"
        ? "ml-IN"
        : language === "KN"
        ? "kn-IN"
        : "en-IN", // Default fallback
      sessionId,
      userName.current,
      userMobileNumber.current,
      newSession
    );
    try {
      stream = await getMediaStream();
    } catch (error) {
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        alert(
          "Microphone access is necessary for the application to function. Please enable microphone access in your browser settings."
        );
      } else {
        alert(
          "An error occurred while accessing the microphone. Please try again later."
        );
      }
      return;
    }
    audioContextRef.current = new window.AudioContext();
    await audioContextRef.current.audioWorklet.addModule(
      "https://d3f67ez5tfvtll.cloudfront.net/Butati/Resources/recorderWorkletProcessor.js"
    );
    audioContextRef.current.resume();
    audioInputRef.current =
      audioContextRef.current.createMediaStreamSource(stream);
    processorRef.current = new AudioWorkletNode(
      audioContextRef.current,
      "recorder.worklet"
    );
    processorRef.current.connect(audioContextRef.current.destination);
    audioContextRef.current.resume();
    audioInputRef.current.connect(processorRef.current);
    processorRef.current.port.onmessage = (event) => {
      const audioData = event.data;
      connection.emit("send_audio_data", { audio: audioData });
    };
    audioRefAlt.current.play();

    setIsPlaying(false);
    setAudioSrc(
      "https://d3f67ez5tfvtll.cloudfront.net/Butati/Resources/start-13691.mp3"
    );
    setIsPlaying(true);

    setIsRecording(true);
    timeoutRef.current = setTimeout(() => {
      stoppedForcefully();
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, 10000);

    // Reset popup triggers
    showLocationPopupTrigger.current = false;
    showConfigurationPopupTrigger.current = false;
    showFilterPopupTrigger.current = false;
    showSiteBookingTrigger.current = false;
  };

  const popupTimeoutRef = useRef(null);

  const handleRestrictBotResponse = () => {
    restrictBotResponse.current = true;
    setIsThinking(false);
    setIsTalking(false);
    setIsPlaying(false);
    setResponseReady(true);
    // setShouldStopSlideshow(true)

    // Clear any pending popups
    if (showLocationPopupTrigger.current)
      showLocationPopupTrigger.current = false;
    if (showConfigurationPopupTrigger.current)
      showConfigurationPopupTrigger.current = false;
    if (showFilterPopupTrigger.current) showFilterPopupTrigger.current = false;
    if (showSiteBookingTrigger.current) showSiteBookingTrigger.current = false;
    // Explicitly set popup states to false
    // setShowLocationPopup(false);
    // setShowConfigurationPopup(false);
    // setShowFilterPopup(false);
    // setShowSiteBooking(false);

    if (window.popupTimeout) {
      clearTimeout(window.popupTimeout);
    }
  };

  useEffect(() => {
    const handleBotAction = (action) => {
      console.log("Bot Action:", action);
      // Add more detailed logging as needed
    };

    // Example: Log when the bot starts listening
    if (isRecording) {
      handleBotAction("Started Listening");
    }

    // Example: Log when the bot stops listening
    if (!isRecording) {
      handleBotAction("Stopped Listening");
    }

    // Add more logging for other bot actions
  }, [isRecording]);

  const handleShowDefaultItems = () => {
    navigationProp.setShowSubItems(false);
    navigationProp.setMenuItems(navigationProp.defaultMenu);
    playSubItem("Default");
  };

  const playSubItem = (data) => {
    navigationProp.setCurrentSubItem(data);
    navigationProp.setPlayIndex(0);
    const newMediaList = [];
    if (mediaDictionary[data].images.length) {
      newMediaList.push(...mediaDictionary[data].images);
    }
    if (mediaDictionary[data].videos.length) {
      newMediaList.push(...mediaDictionary[data].videos);
    }
    navigationProp.setMediaList(newMediaList);
  };

  const handleDivClick = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    const streamingVideoDiv = document.getElementById("streamingVideo");
    if (streamingVideoDiv && inputMsg.length) {
      streamingVideoDiv.addEventListener("click", handleDivClick);
    }
    return () => {
      if (streamingVideoDiv) {
        streamingVideoDiv.removeEventListener("click", handleDivClick);
      }
    };
  }, [inputMsg]);

  const handleKeyPress = (event) => {
    if (inputMsg.length && event.key === "Enter") {
      startRecording();
    }
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    const handleOverflowChange = () => {
      const isOverflowing =
        chatContainerRef.current.scrollHeight >
        chatContainerRef.current.clientHeight;
      chatContainerRef.current.style.justifyContent = isOverflowing
        ? "flex-start"
        : "center";
    };
    handleOverflowChange();
    chatContainerRef.current.addEventListener("scroll", handleOverflowChange);
    return () => {
      chatContainerRef.current.removeEventListener(
        "scroll",
        handleOverflowChange
      );
    };
  }, [chatMessages, chatContainerRef]);

  const [customClassName, setCustomClassName] = useState("fs-sm");

  useEffect(() => {
    const handleResize = () => {
      const newClassName = window.innerWidth >= 950 ? "fs-xs" : "fs-sm";
      setCustomClassName(newClassName);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const audioUrls = {
    Entryintro: "",
    LivingArea:
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Livingroom.wav",
    Kitchen:
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Kitchen.wav",
    Masterbedroom:
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Masterbedroom.wav",
    Secondbedroom:
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Secondbedroom.wav",
    Exitintro:
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/End.wav",
  };

  const messages = {
    Entryintro: "",
    LivingArea:
      "Step inside our spacious living room, bathed in natural light, it is designed for both relaxation and entertainment.",
    Kitchen:
      "The kitchen adjoins a dining area, ensuring maximum circulation and ample ventilation throughout. with dedicated space for fridge and washing machine.",
    Masterbedroom:
      "Welcome to the serene master bedroom, thoughtfully designed with an attached toilet and excellent ventilation, ensuring a tranquil and airy ambiance.",
    Secondbedroom:
      "Our second bedroom, complete with an attached toilet. Containing a sofa cum bed, and a wardrobe, creating a comfortable and functional space.",
    Exitintro:
      "I'm sure you've experienced the comfort and convenience of your potential new home. What specific questions or preferences would you like to explore further?",
  };

  const visemes = {
    LivingArea: LivingAreaData,
  };

  const revokeMicrophoneAccess = () => {
    return new Promise((resolve, reject) => {
      if (stream) {
        const trackStopPromises = stream
          .getTracks()
          .map((track) => track.stop());
        Promise.all(trackStopPromises)
          .then(() => {
            setTimeout(() => {
              resolve();
            }, 500);
          })
          .catch((error) => {
            console.error("Error stopping tracks:", error);
            reject(error);
          });
      } else {
        setTimeout(() => {
          resolve();
        }, 500);
      }
    });
  };

  const isFirstAudioFinished = useRef(false);

  const introMessages = {
    "en-IN":
      "Hey! I'm Sia, your AI guide to Nine Reflex. How can I assist you?",
    "hi-IN":
      "नमस्ते! मैं सिया, नाइन रिफ्लेक्स के लिए आपकी एआई गाइड हूँ। मैं आपकी कैसे मदद कर सकती हूँ?",
  };

  const audioUrlss = {
    "en-IN":
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/ElevenLabsAudio/tourintroEN.mp3",
    "hi-IN":
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/ElevenLabsAudio/tourintroHI.mp3",
  };

  useEffect(() => {
    const handleAudioStop = async () => {
      if (startClicked && !isFirstAudioFinished.current) {
        isFirstAudioFinished.current = true;
        if (
          audioContextRef.current &&
          audioContextRef.current.state !== "closed"
        ) {
          audioContextRef.current.close();
        }

        await revokeMicrophoneAccess();

        // Set the current recognition text
        setChatMessages((prevMessages) =>
          appendMessage(prevMessages, introMessages[language], "bot")
        );

        setIsTalking(true);

        topLoadingRef?.current?.complete();

        // Set the audio source based on the selected language
        setAudioSrc(audioUrlss[language]);
        setIsPlaying(true);
      }

      setVisemeData(visemes["LivingArea"]);
    };
    handleAudioStop();
  }, [startClicked]);

  const [siteBookingDate, setSiteBookingDate] = useState(new Date());

  const handleSiteBooking = async (combinedDateTime) => {
    if (!combinedDateTime) return;

    topLoadingRef.current.continuousStart();

    try {
      console.warn("site booking date sent for", userMobileNumber);

      const response = await axios.post(url + "/user/setbookingdate", {
        mobileNumber: userMobileNumber.current,
        name: userName.current,
        date: combinedDateTime.toString(), // Send the combined date and time
        sessionId: session.current,
      });

      await revokeMicrophoneAccess();

      newResponseMsg.current =
        language === "hi-IN"
          ? "आपकी साइट का दौरा निर्धारित है. हमारी बिक्री टीम शीघ्र ही आपसे संपर्क करेगी."
          : language === "MA"
          ? "तुमची साइट भेट शेड्यूल केली आहे. आमची विक्री टीम लवकरच तुमच्याशी संपर्क साधेल."
          : "Your site visit is scheduled. Our sales team will contact you shortly.";
      // setShouldCloseCalendar(true)
      setTimeout(() => {
        topLoadingRef?.current?.complete();
        setShowSiteBookingForm(false);
        generateTTS.current(newResponseMsg.current);
      });
    } catch (error) {
      console.error("error sending site booking data:", error);

      await revokeMicrophoneAccess();

      newResponseMsg.current =
        language === "hi-IN"
          ? "अपनी साइट यात्रा शेड्यूल करने में असमर्थ। कृपया फिर कोशिश करें।"
          : language === "MA"
          ? "तुमची साइट भेट शेड्यूल करण्यात अक्षम. कृपया पुन्हा प्रयत्न करा."
          : "Unable to schedule your site visit. Please try again.";

      // setShouldCloseCalendar(true)
      setTimeout(() => {
        topLoadingRef?.current?.complete();
        setShowSiteBookingForm(false);
        generateTTS.current(newResponseMsg.current);
      });
    }
  };

  // useEffect(() => {
  //   if (shouldCloseCalendar) {
  //     datePickerRef?.current?.closeCalendar();
  //   }
  // }, [shouldCloseCalendar]);

  const startButtonClicked = useRef(false);

  const startGame = async () => {
    if (startButtonClicked.current === false) {
      // topLoadingRef.current.continuousStart();
      try {
        console.warn("login time sent for", userMobileNumber.current);

        const endpoint = isAgent ? "/agent/login" : "/user/login";
        const response = await axios.post(url + endpoint, {
          mobileNumber: userMobileNumber.current,
          name: userName.current,
        });
        // setTimeout(() => {
        // topLoadingRef.current.complete();
        // }, 1000);
      } catch (error) {
        console.error("error for login time:", error);
        // setTimeout(() => {
        // topLoadingRef?.current?.complete();
        // }, 1000);
      }

      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        // console.log('Mic permission granted');
        // setShowLoadingScreen(true)
        setTimeout(() => {
          setStartClicked(true);
          // endCinematics()
        }, 2000);
      } catch (error) {
        console.error("Error accessing media stream:", error);
        // window.location.reload()
        setTimeout(() => {
          setStartClicked(true);
          // endCinematics()
        }, 2000);
      }

      setIsMuted(false);
      // pixelStreamingConfig.resumePSGame()
      startButtonClicked.current = true;
    }
  };

  const handleShareClick = async () => {
    const shareUrl = "https://ninereflex.xrvizion.com/";
    const thumbnailUrl =
      "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/share_thumbnail.png"; // Replace with the actual URL of your thumbnail image

    const shareData = {
      title: `Explore Nine Reflex's AI powered Virtual Tour by XRVizion`,
      text: `See what Nine Reflex's has to offer. ${shareUrl}`,
      url: shareUrl,
    };

    // Fetch the image as a blob
    const response = await fetch(thumbnailUrl);
    const blob = await response.blob();
    const file = new File([blob], "thumbnail.png", {
      type: blob.type,
    });

    if (
      navigator.canShare &&
      navigator.canShare({
        files: [file],
        ...shareData,
      })
    ) {
      try {
        await navigator.share({
          files: [file],
          ...shareData,
        });
        console.log("Successful share with image");
      } catch (error) {
        console.log("Error sharing with image", error);
      }
    } else if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
        console.log("Successful share without image");
      } catch (error) {
        console.log("Error sharing without image", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      const tempInput = document.createElement("input");
      tempInput.value = shareUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert("Link copied to clipboard!");
    }
  };


  const usePreDefinedQuestion = (que) => {
    if (que.length) {
      if (!connection) return;
      shouldRecordAgain.current = false;
      stopAudio();
      // setButtonText("Thinking...");
      restrictBotResponse.current = false;
      setChatMessages((prevMessages) =>
        appendMessage(prevMessages, que, "user")
      );
      let newSession = session.current === 0;
      let sessionId = newSession ? 0 : session.current;
      connection.emit(
        "startTextInput",
        language === "en-IN" ? "en-IN" : language === "MR" ? "mr-IN" : "hi-IN",
        sessionId,
        que,
        userName.current,
        userMobileNumber.current,
        newSession
      );
      setIsThinking(true);
      setIsPlaying(false);
    }
  };

  const handlePlaceSelected = (place) => {
    const location = place.formatted_address;
    setShowLocationPopup(false);
    setIsThinking(true); // Set thinking state to true

    // Send the location data to the backend
    sendLocationToBackend(
      userMobileNumber.current,
      userName.current,
      location,
      session.current
    );

    // Introduce a delay before sending the location message to the backend
    setTimeout(() => {
      if (connection) {
        connection.emit(
          "startTextInput",
          language === "en-IN"
            ? "en-IN"
            : language === "MR"
            ? "mr-IN"
            : "hi-IN",
          session.current,
          location,
          userName.current,
          userMobileNumber.current,
          session.current === 0
        );
      }

      // Update the chat messages
      setChatMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        if (
          newMessages.length > 0 &&
          newMessages[newMessages.length - 1].content === location
        ) {
          return newMessages; // Avoid duplication
        }
        const updatedMessages = appendMessage(newMessages, location, "user");
        setLocationSent(true); // Mark the location as sent
        return updatedMessages;
      });
    }, 3000); // 3-second delay
  };

  const handleConfigurationSelected = (configuration) => {
    setShowConfigurationPopup(false);
    setIsThinking(true); // Set thinking state to true

    // Send the configuration data to the backend
    sendConfigurationToBackend(
      userMobileNumber.current,
      userName.current,
      configuration,
      session.current
    );

    // Introduce a delay before sending the configuration message to the backend
    setTimeout(() => {
      if (connection) {
        connection.emit(
          "startTextInput",
          language === "en-IN"
            ? "en-IN"
            : language === "MR"
            ? "mr-IN"
            : "hi-IN",
          session.current,
          configuration,
          userName.current,
          userMobileNumber.current,
          session.current === 0
        );
      }

      // Update the chat messages
      setChatMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        if (
          newMessages.length > 0 &&
          newMessages[newMessages.length - 1].content === configuration
        ) {
          return newMessages; // Avoid duplication
        }
        const updatedMessages = appendMessage(
          newMessages,
          configuration,
          "user"
        );
        setConfigurationSent(true); // Mark the configuration as sent
        return updatedMessages;
      });
    }, 3000); // 3-second delay
  };

  const topLoadingRef = useRef(null);

  const dropdownRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    // setShowDropdown(false);
  };

  useEffect(() => {
    startGame();
    setStartClicked(true);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigate = (targetRoom) => {
    setCurrentRoom(targetRoom);
    if (roomData[targetRoom]) {
      setCurrentRoom(targetRoom);
    }
  };

  useEffect(() => {
    if (currentSubItem && roomData[currentSubItem]) {
      setCurrentRoom(currentSubItem);
    }
  }, [currentSubItem]);

  const roomData = {
    "Living room": {
      images: [],
      videos: [],
      panoramic: ["Living+Room.webp"],
    },
    Kitchen: {
      images: [],
      videos: [],
      panoramic: ["Kitchen.webp"],
    },
    Bedroom: {
      images: [],
      videos: [],
      panoramic: ["Bedroom.webp"],
    },
    "Dining room": {
      images: [],
      videos: [],
      panoramic: ["Dining.webp"],
    },
    Bathroom: {
      images: [],
      videos: [],
      panoramic: ["Bathroom.webp"],
    },
  };

  const [currentRoom, setCurrentRoom] = useState("Living room");

  const currentMedia = roomData[currentRoom].panoramic[0];

  // const handleLogout = async () => {
  //   if (connection) connection.disconnect();
  //   try {
  //     await fetch(url + "/user/logout", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         mobileNumber: userMobileNumber.current,
  //         name: userName.current,
  //       }),
  //     });
  //     // Redirect to login page or perform other logout actions
  //     // window.location.reload();
  //   } catch (error) {
  //     console.error("Logout failed", error);
  //   }
  // };

  const handleLogout = () => {
    if (connection) connection.disconnect();
    const now = new Date();
    localStorage.setItem("logoutTime", now.toISOString());
    localStorage.setItem("userMobileNumber", userMobileNumber.current);
    localStorage.setItem("userName", userName.current);
    window.location.href = "/feedback"; // Redirect to the feedback page
  };

  const mediaRef = useRef(null);
  useEffect(() => {
    if (mediaRef.current) {
      // Fade out
      anime({
        targets: mediaRef.current,
        opacity: [0.2, 1],
        duration: 1000,
        easing: "easeOutSine",
        // complete: () => {
        //   // Fade in after content changes
        //   anime({
        //     targets: mediaRef.current,
        //     opacity: [0, 1],
        //     duration: 1000,
        //     easing: "easeOutSine",
        //   });
        // },
      });
    }
  }, [navigationProp.currentSubItem]); // Run when the media index changes

  useEffect(() => {
    if (startingToRecord) {
      setButtonText("Preparing...");
    } else if (isRecording) {
      setButtonText("Listening...");
    } else if (isThinking) {
      setButtonText("Thinking...");
    } else if (isTalking) {
      setButtonText("Talking...");
    } else {
      setButtonText("Ask me");
    }
  }, [startingToRecord, isRecording, isThinking, isTalking]);

  const handleDownload = async () => {
    try {
      const response = await fetch('https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/ninereflex.pdf');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ninereflex.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };


  return (
    <>
      <InactivityLogout onLogout={handleLogout} />

      <div className="gradient"></div>
      <Navigation
        navigationProp={navigationProp}
        toggleAIChat={toggleAIChat}
        setToggleAIChat={setToggleAIChat}
        updateMedia={updateMedia}
        keywordsToPlay={keywordsToPlay}
        setKeywordsToPlay={setKeywordsToPlay}
      />

      <div
        id="dropdown-cont"
        className={`dropdown-cont ${showDropdown ? "active" : ""}`}
        ref={dropdownRef}
        onClick={() => setShowDropdown(true)}
      >
        {!showDropdown && (
          <div>
            <BsInfoLg size={18} />
          </div>
        )}
        {showDropdown && (
          <div className="dropdown-cont-active">
            <div className="dropdown-close-icon">
              <div
                className="dropdown-close-icon-cont"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from bubbling up
                  setShowDropdown(false);
                }}
              >
                <IoClose color="rgb(0 0 0 / 90%)" size={20} />
              </div>
            </div>
            <div
              style={{
                marginTop: "2rem",
                marginLeft: window.innerWidth > 768 ? "1.25rem" : "0.5rem",
              }}
            >
              <div>
                <div
                  className={`${
                    window.innerWidth > 768 ? "ff-r fs-sm" : "ff-r fs-m"
                  }`}
                >
                  Change Language
                </div>
                <select
                  className="text-input-new ff-m fs-sm custom-select"
                  required
                  onChange={(e) => handleLanguageChange(e.target.value)}
                >
                  <option
                    selected={language === "en-IN" ? true : ""}
                    value="en-IN"
                  >
                    English
                  </option>
                  <option
                    selected={language === "hi-IN" ? true : ""}
                    value="hi-IN"
                  >
                    Hindi
                  </option>
                </select>
              </div>
              <div
                className={`dropdown-menu-item ${
                  window.innerWidth > 768 ? "ff-r fs-sm" : "ff-r fs-m"
                }`}
                style={{
                  marginTop: window.innerWidth > 768 ? "1.5rem" : "1rem",
                }}
              >
              <p
                  onClick={handleDownload}
                >
                  Download Brochure
                </p>
              </div>
              <div
                className={`dropdown-menu-item ${
                  window.innerWidth > 768 ? "ff-r fs-sm" : "ff-r fs-m"
                }`}
                style={{
                  marginTop: window.innerWidth > 768 ? "1.5rem" : "1rem",
                }}
              >
                <a
                  href="https://ninereflex.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  About Nine Reflex
                </a>
              </div>
              <div
                className="social-icons-dropdown"
                style={{
                  marginTop: window.innerWidth > 768 ? "0.75rem" : "0.75rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://www.instagram.com/reflex_realty/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram
                    size={window.innerWidth > 768 ? "27" : "25"}
                    style={{ marginRight: "0.75rem" }}
                  />
                </a>
                <a href="https://www.facebook.com/reflexrealty/">
                  <FaFacebookF
                    size={window.innerWidth > 768 ? "24" : "22"}
                    style={{ marginRight: "0.75rem" }}
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=919321203280"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp
                    size={window.innerWidth > 768 ? "27.5" : "25.5"}
                    style={{ marginRight: "0.75rem" }}
                  />
                </a>
              </div>
              <div className="xrv-logo-cont-more-options">
                <a href="https://xrvizion.com" target="_blank" rel="noreferrer">
                  <img
                    src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/vanheusen/Resources/xrv-logo.png"
                    alt=""
                  ></img>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      {preloadedMedia[navigationProp.currentSubItem] &&
      ["images", "videos", "panoramic"].some(
        (type) =>
          preloadedMedia[navigationProp.currentSubItem][type] &&
          preloadedMedia[navigationProp.currentSubItem][type].length > 0
      ) ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
          }}
        >
          {checkFileType(navigationProp.mediaList[navigationProp.playIndex]) ===
          "image" ? (
            <img
              ref={mediaRef}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={`${preloadedMedia[navigationProp.currentSubItem].images[0]}`}
              alt=""
            />
          ) : checkFileType(
              navigationProp.mediaList[navigationProp.playIndex]
            ) === "video" ? (
            <ReactPlayer
              ref={mediaRef}
              url={`${preloadedMedia[navigationProp.currentSubItem].videos[0]}`}
              loop={true}
              controls={false}
              volume={1}
              muted={true}
              playing={startClicked}
              playsinline={true}
              width={"unset"}
              height={"unset"}
              style={{
                width: "100%",
                height: "100%",
                position: window.innerWidth < 768 ? "absolute" : "",
                top: window.innerWidth < 768 ? "50%" : "",
                transform: window.innerWidth < 768 ? "translateY(-50%)" : "",
              }}
            />
          ) : (
           <PanoramaViewer
              navigationProp={navigationProp}
              // imagePath={`${preloadedMedia[navigationProp.currentSubItem].panoramic[0]}`}
              imagePath={`https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Media/${navigationProp.mediaList[navigationProp.playIndex]
                }`}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
          }}
        >
          {checkFileType(navigationProp.mediaList[navigationProp.playIndex]) ===
          "image" ? (
            <img
              ref={mediaRef}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={`https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/Media/${
                navigationProp.mediaList[navigationProp.playIndex]
              }`}
              alt=""
            />
          ) : checkFileType(
              navigationProp.mediaList[navigationProp.playIndex]
            ) === "video" ? (
            <ReactPlayer
              ref={mediaRef}
              url={`https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/Media/${
                navigationProp.mediaList[navigationProp.playIndex]
              }`}
              loop={true}
              controls={false}
              volume={1}
              muted={true}
              playing={startClicked}
              playsinline={true}
              width={"unset"}
              height={"unset"}
              style={{
                width: "100%",
                height: "100%",
                position: window.innerWidth < 768 ? "absolute" : "",
                top: window.innerWidth < 768 ? "50%" : "",
                transform: window.innerWidth < 768 ? "translateY(-50%)" : "",
              }}
            />
          ) : (
            <PanoramaViewer
              navigationProp={navigationProp}
              imagePath={`https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Media/${navigationProp.mediaList[navigationProp.playIndex]}`}
              socket={connection}
              showLocationPopup={showLocationPopup}
              setShowLocationPopup={setShowLocationPopup}
              userName={userName}
              userMobileNumber={userMobileNumber}
              setShowFilterPopup={setShowFilterPopup}
              handleLikeClick={handleLikeClick}
              handleRequestCallback={handleRequestCallback}
            />
          )}
        </div>
      )}
      {/* <Tooltip
        id="mic"
        content="Tap to speak"
        className="ff-m"
        isOpen={isTooltipOpen}
        style={{ zIndex: "10000" }}
        place="top-end"
      /> */}

      {/* <Tooltip
        id="mute"
        content="Click to mute / unmute"
        className="ff-m"
        isOpen={isTooltipOpen}
        style={{ zIndex: "10000" }}
        place="left"
      /> */}

      {/* <Tooltip
        id="mode"
        content="Click to change modes"
        className="ff-m"
        isOpen={isTooltipOpen}
        style={{ zIndex: "10000" }}
        place="left"
      /> */}

      {/* {
        !startClicked
        &&
        <Welcome startGame={startGame} />
      } */}

      {showLocationPopup && (
        <LocationPopup
          onClose={() => setShowLocationPopup(false)}
          onPlaceSelected={handlePlaceSelected}
        />
      )}

      {showConfigurationPopup && (
        <ConfigurationPopup
          onClose={() => setShowConfigurationPopup(false)}
          onConfigurationSelected={handleConfigurationSelected}
        />
      )}

      {showFilterPopup && (
        <FilterPopup
          onClose={() => setShowFilterPopup(false)}
          onFilterSelected={handleFilterSelected}
        />
      )}

      {/* {window.innerWidth < 768 && (
            <IoIosShareAlt
              style={{
                color: 'white',
                fontSize: '1.5rem',
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
                padding: '10px',
                marginTop: '95px',
                marginLeft: '4rem'
              }}
              onClick={() => setShowCustomPopup(true)}
          
              className='dropdown-cont' 
            />
          )} */}
      {/* 
      {showCustomPopup && (
        <div className="popup">
          <p>Are you sure you want to leave?</p>
          <button
            onClick={() => {
              setShowCustomPopup(false);
              // Handle leave
              window.close();
            }}
          >
            Leave
          </button>
          <button onClick={() => setShowCustomPopup(true)}>Stay</button>
        </div>
      )} */}

      {showCustomPopup && (
        <CustomCallbackPopup
          onClose={() => setShowCustomPopup(false)}
          onRequestCallback={handleCallbackClick}
          isLiked={isLiked}
          onLikeClick={handleLikeClick}
          onShareClick={handleShareClick}
          userName={userName}
          userMobileNumber={userMobileNumber}
        />
      )}

      {showCustom && (
        <CustomCallbackPopup1
          onClose={() => setShowCustom(false)}
          onRequestCallback={handleCallbackClick}
          onShareClick={handleShareClick}
          userName={userName}
          userMobileNumber={userMobileNumber}
        />
      )}

      {window.innerWidth < 768 && (
        <div
          style={{
            position: "absolute",
            top: "3.2rem",
            right: "1rem",
            zIndex: 0,
            cursor: "pointer",
            borderRadius: "5rem",
            color: "#fff",
            width: "fit-content",
            marginTop: "2rem",
            padding: "0.6rem", // Adjusted padding for even spacing
            border: "1px solid #ffffff",
            backgroundColor: "#00000076",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setShowCustom(true)}
        >
          <IoShareSocial size={21} color="white" />
        </div>
      )}

      <div
          style={{
            position: "fixed",
            top: "4rem",
            right: "0.005rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <BsCurrencyDollar
            style={{
              color: "white",
              fontSize: "1.5rem",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.55) !important",
              padding: "10px",
              marginLeft: "4rem",
              zIndex: "1000001",
            }}
            onClick={() => setShowFilterPopup(true)}
            className="dropdown-cont"
          /> */}
          {/* {window.innerWidth < 768 && (
            <IoIosShareAlt
              style={{
                color: 'white',
                fontSize: '1.5rem',
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
                padding: '10px',
                marginTop: '10px',
                marginLeft: '4rem'
              }}
              onClick={() => setShowCustomPopup(true)}
            />
          )} */}
        </div>
      </div>

      <div className="like-button" onClick={() => handleLikeClick(true)}>
        <GoHeartFill size={24} color={isLiked ? "red" : "white"} />
      </div>

      <style jsx>{`
        .like-button {
          position: fixed;
          top: 5.8rem;
          right: 2rem;
          z-index: 0;
          cursor: pointer;
          border-radius: 5rem;
          color: #fff;
          width: fit-content;
          padding: 0.6rem 0.5rem 0.4rem 0.5rem;
          border: 1px solid #ffffff;
          background-color: #00000076;
          display: flex;
          align-items: center;
        }

        @media (max-width: 768px) {
          .like-button {
            top: 8.3rem;
            right: 1rem;
            padding: 0.6rem 0.5rem 0.4rem 0.5rem;
          }
        }
      `}</style>

      <div
        style={{
          position: "fixed",
          top: "4rem",
          right: "0.005rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <BsCurrencyDollar
          style={{
            color: "white",
            fontSize: "1.5rem",
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.463) !important", // Updated background color
            padding: "10px",
            marginLeft: "4rem",
          }}
          onClick={() => setShowFilterPopup(true)}
          className="dropdown-cont"
        /> */}

          {/* {window.innerWidth < 768 && (
            <IoIosShareAlt
              style={{
                color: 'white',
                fontSize: '1.5rem',
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
                padding: '10px',
                marginTop: '10px',
                marginLeft: '4rem'
              }}
              onClick={() => setShowCustomPopup(true)}
            />
          )} */}
        </div>
      </div>

      <LoadingBar color="#edd795" ref={topLoadingRef} shadow={true} />
      <TextToSpeech
        shouldPlayResponse={shouldPlayResponse}
        textToSpeak={textToSpeak}
        playAudio={playAudio}
        language={props.language}
        revokeMicrophoneAccess={revokeMicrophoneAccess}
        setAudioSrc={setAudioSrc}
        setIsPlaying={setIsPlaying}
        textToSpeechResponse={textToSpeechResponse}
      />

      {!(showSiteBooking || showSiteBookingForm) && (
        <div
          className={`divider ${
            navigationProp.siteOnlyVersion.current && "siteonly"
          }`}
          id="divider"
        >
          <img
            onClick={() => setToggleAIChat((prev) => !prev)}
            src="https://d3f67ez5tfvtll.cloudfront.net/Aadhya/Resources/arrow-down.png"
            alt=""
          />
        </div>
      )}

      <div
        className={`ui-container ${
          navigationProp.siteOnlyVersion.current && "siteonly"
        }`}
      >
        {showSiteBooking && (
          <SiteBooking
            setShowSiteBooking={setShowSiteBooking}
            setShowSiteBookingForm={setShowSiteBookingForm}
            datePickerRef={datePickerRef}
          />
        )}
        {showSiteBookingForm && (
          <DayDatePicker
            handleSiteBooking={handleSiteBooking}
            siteBookingDate={siteBookingDate}
            setSiteBookingDate={setSiteBookingDate}
            shouldOpenCalendar={shouldOpenCalendar}
            shouldCloseCalendar={shouldCloseCalendar}
            setShouldOpenCalendar={setShouldOpenCalendar}
            setShouldCloseCalendar={setShouldCloseCalendar}
            datePickerRef={datePickerRef}
          />
        )}
        <div className="input-handler-cont">
          <div className="line"></div>
          <div className="ai-bot">
            <BotHandler
              isTalking={isTalking}
              isThinking={isThinking}
              isListening={isListening}
              visemeData={visemeData}
            />
          </div>
          <div className="ai-bot-section">
            <div
              className={`bot-response ${
                navigationProp.siteOnlyVersion.current && "siteonly"
              }`}
              ref={chatContainerRef}
            >
              {chatMessages.map((message) => (
                <div
                  className={`bot-response-text ff-r ${customClassName} ${
                    message.from === "bot" ? "bot-msg" : "user-msg"
                  }`}
                >
                  {message.content === "..." ? (
                    <BeatLoader
                      color="#c4c4c4"
                      margin={2.25}
                      size={7.5}
                      speedMultiplier={0.8}
                    />
                  ) : (
                    message.content
                  )}
                </div>
              ))}
              {isThinking && (
                <div className="bot-response-text ff-r bot-msg">
                  <TypingAnimation />
                </div>
              )}
            </div>
            <div className="pre-ques-cont">
              <PreQuestions
                preDefinedQuestion={usePreDefinedQuestion}
                isRecording={isRecording}
                responseReady={responseReady}
                isThinking={isThinking}
                startingToRecord={startingToRecord}
                userLanguage={language}
                navigationProp={navigationProp}// Pass currentSubItem as a prop
              />
            </div>
            <div className={`input-section noselect`}>
              <div className="bg-cover"></div>
              <div
                className="text-input-section"
                style={{ width: inputMsg.length ? "85%" : "85%" }}
              >
                <input
                  ref={inputRef}
                  disabled={
                    isRecording ||
                    responseReady === false ||
                    isThinking ||
                    startingToRecord ||
                    isTalking
                  }
                  onKeyPress={handleKeyPress}
                  onChange={(event) => setInputMsg(event.target.value)}
                  value={inputMsg}
                  placeholder={buttonText}
                  className={`text-input ff-m ${customClassName}`}
                  type="text"
                  onFocus={handleInputFocus} // Add this line
                ></input>
              </div>
              <div
                className="controls-section"
                style={{ width: inputMsg.length ? "15%" : "15%" }}
              >
                <div
                  className="btn-wrapper"
                  style={{ justifyContent: inputMsg.length ? "left" : "left" }}
                >
                  {isThinking || isTalking ? (
                    <div onClick={handleRestrictBotResponse}>
                      <CgClose
                        color={"rgb(255, 255, 255, 0.7)"}
                        size={23}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : inputMsg.length ? (
                    <VscSend
                      disabled={startingToRecord || responseReady === false}
                      className="ui-rec-btn active ff-m"
                      onClick={startRecording}
                      color="rgba(255, 255, 255, 0.7)"
                      size={19}
                    />
                  ) : (
                    <>
                      <a data-tooltip-id="mic">
                        <button
                          className="ui-rec-btn-cont"
                          disabled={startingToRecord || responseReady === false}
                          onClick={
                            startingToRecord || responseReady === false
                              ? null
                              : startRecording
                          }
                          style={{
                            background: "transparent",
                            padding: "0",
                            border: "none",
                          }}
                        >
                          {isRecording ? (
                            <PiMicrophoneFill
                              className="ui-rec-btn active ff-m animate-opacity"
                              color={"#fc3d39"}
                              size={23}
                            />
                          ) : (
                            <PiMicrophone
                              className={`ui-rec-btn active ff-m ${
                                startingToRecord || responseReady === false
                                  ? "disabled"
                                  : ""
                              }`}
                              color={"rgb(255, 255, 255, 0.7)"}
                              size={23}
                            />
                          )}
                        </button>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioToText;
