import React, { useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

const PreQuestions = ({ preDefinedQuestion, isRecording, responseReady, isThinking, startingToRecord, userLanguage, navigationProp }) => {

  const roomQuestionsData = {
    "en-IN": {
      "Living Room": [
        "What is the size of the living room?",
        "Is there provision for TV mounting?",
        "What is the flooring material used?",
        "How many windows are there?",
        "Is there a false ceiling provision?"
      ],
      "Kitchen": [
        "What is the kitchen layout?",
        "Is there a utility area attached?",
        "What type of countertop is provided?",
        "Are modular cabinets included?",
        "Is there a separate dry kitchen?"
      ],
      "Bathroom 1": [
        "What are the bathroom fittings?",
        "Is there a bathtub provision?",
        "What type of tiles are used?",
        "Is there a separate shower area?",
        "What is the geyser capacity?",
        "Is there a ventilation system?",
        "What is the size of the bathroom?",
        "Is there a storage cabinet?",
        "What is the lighting arrangement?",
        "Is there a mirror installed?"
      ],
      "Bathroom 2": [
        "What are the bathroom fittings?",
        "Is there a bathtub provision?",
        "What type of tiles are used?",
        "Is there a separate shower area?",
        "What is the geyser capacity?",
        "Is there a ventilation system?",
        "What is the size of the bathroom?",
        "Is there a storage cabinet?",
        "What is the lighting arrangement?",
        "Is there a mirror installed?"
      ],
      "Dining Area": [
        "What is the dining area size?",
        "Is it connected to the kitchen?",
        "What is the maximum seating capacity?",
        "Is there a crockery unit provision?",
        "What is the lighting arrangement?"
      ],
      "Study Room": [
        "What is the size of the study room?",
        "Is there a built-in bookshelf?",
        "What type of flooring is used?",
        "How many electrical outlets are there?",
        "Is there a provision for a study table?",
        "What is the lighting arrangement?",
        "Is there a storage cabinet?",
        "Is there a provision for a computer desk?",
        "What is the ventilation system?",
        "Is there a provision for a whiteboard?"
      ],
      "Bedroom": [
        "What is the size of the bedroom?",
        "Is there a built-in wardrobe?",
        "What type of flooring is used?",
        "How many electrical outlets are there?",
        "Is there a provision for a king-size bed?",
        "What is the lighting arrangement?",
        "Is there a dressing table?",
        "Is there a provision for a side table?",
        "What is the ventilation system?",
        "Is there a provision for a TV?"
      ]
    },
    "hi-IN": {
      "Living Room": [
        "लिविंग रूम का साइज क्या है?",
        "टीवी माउंटिंग का प्रावधान है क्या?",
        "फर्श की सामग्री क्या है?",
        "कितनी खिड़कियां हैं?",
        "फॉल्स सीलिंग का प्रावधान है क्या?"
      ],
      "Kitchen": [
        "किचन का लेआउट कैसा है?",
        "क्या यूटिलिटी एरिया अटैच है?",
        "काउंटरटॉप किस प्रकार का है?",
        "मॉड्यूलर कैबिनेट शामिल हैं क्या?",
        "क्या अलग ड्राई किचन है?"
      ],
      "Bathroom 1": [
        "बाथरूम फिटिंग क्या हैं?",
        "क्या बैथटब का प्रावधान है?",
        "कौन से प्रकार के टाइल्स इस्तेमाल किए गए हैं?",
        "क्या अलग शावर एरिया है?",
        "गीजर की क्षमता क्या है?",
        "क्या वेंटिलेशन सिस्टम है?",
        "बाथरूम का साइज क्या है?",
        "क्या स्टोरेज कैबिनेट है?",
        "लाइटिंग व्यवस्था क्या है?",
        "क्या मिरर इंस्टॉल किया गया है?"
      ],
      "Bathroom 2": [
        "बाथरूम फिटिंग क्या हैं?",
        "क्या बैथटब का प्रावधान है?",
        "कौन से प्रकार के टाइल्स इस्तेमाल किए गए हैं?",
        "क्या अलग शावर एरिया है?",
        "गीजर की क्षमता क्या है?",
        "क्या वेंटिलेशन सिस्टम है?",
        "बाथरूम का साइज क्या है?",
        "क्या स्टोरेज कैबिनेट है?",
        "लाइटिंग व्यवस्था क्या है?",
        "क्या मिरर इंस्टॉल किया गया है?"
      ],
      "Study Room": [
        "स्टडी रूम का साइज क्या है?",
        "क्या बिल्ट-इन बुकशेल्फ है?",
        "फर्शिंग किस प्रकार की है?",
        "कितने इलेक्ट्रिकल आउटलेट हैं?",
        "क्या स्टडी टेबल का प्रावधान है?",
        "लाइटिंग व्यवस्था क्या है?",
        "क्या स्टोरेज कैबिनेट है?",
        "क्या कंप्यूटर डेस्क का प्रावधान है?",
        "वेंटिलेशन सिस्टम क्या है?",
        "क्या व्हाइटबोर्ड का प्रावधान है?"
      ],
      "Bedroom": [
        "बेडरूम का साइज क्या है?",
        "क्या बिल्ट-इन वार्डरोब है?",
        "फर्शिंग किस प्रकार की है?",
        "कितने इलेक्ट्रिकल आउटलेट हैं?",
        "क्या किंग-साइज बेड का प्रावधान है?",
        "लाइटिंग व्यवस्था क्या है?",
        "क्या ड्रेसिंग टेबल है?",
        "क्या साइड टेबल का प्रावधान है?",
        "वेंटिलेशन सिस्टम क्या है?",
        "क्या टीवी का प्रावधान है?"
      ]
    },
    "MA": {
      "Living Room": [
        "लिव्हिंग रूमचा आकार काय आहे?",
        "टीव्ही माउंटिंगची व्यवस्था आहे का?",
        "फर्शाचे साहित्य काय आहे?",
        "किती खिडक्या आहेत?",
        "फॉल्स सीलिंगची व्यवस्था आहे का?"
      ],
      "Kitchen": [
        "किचनचा लेआउट कसा आहे?",
        "काय यूटिलिटी एरिया अटैच आहे?",
        "काउंटरटॉप कोणत्या प्रकारचा आहे?",
        "मॉड्यूलर कैबिनेट समाविष्ट आहेत का?",
        "काय वेगळा ड्राई किचन आहे?"
      ],
      "Bathroom 1": [
        "बाथरूम फिटिंग काय आहेत?",
        "बॅथटबचा प्रावधान आहे का?",
        "कोणत्या प्रकारचे टाइल्स वापरले गेले आहेत?",
        "काय वेगळा शावर एरिया आहे?",
        "गीजरची क्षमता काय आहे?",
        "काय वेंटिलेशन सिस्टम आहे?",
        "बाथरूमचा आकार काय आहे?",
        "काय स्टोरेज कैबिनेट आहे?",
        "लाइटिंग व्यवस्था काय आहे?",
        "काय मिरर इंस्टॉल केले आहे?"
      ],
      "Bathroom 2": [
        "बाथरूम फिटिंग काय आहेत?",
        "बॅथटबचा प्रावधान आहे का?",
        "कोणत्या प्रकारचे टाइल्स वापरले गेले आहेत?",
        "काय वेगळा शावर एरिया आहे?",
        "गीजरची क्षमता काय आहे?",
        "काय वेंटिलेशन सिस्टम आहे?",
        "बाथरूमचा आकार काय आहे?",
        "काय स्टोरेज कैबिनेट आहे?",
        "लाइटिंग व्यवस्था काय आहे?",
        "काय मिरर इंस्टॉल केले आहे?"
      ],
      "Study Room": [
        "स्टडी रूमचा आकार काय आहे?",
        "काय बिल्ट-इन बुकशेल्फ आहे?",
        "फर्शिंग कोणत्या प्रकारची आहे?",
        "किती इलेक्ट्रिकल आउटलेट आहेत?",
        "काय स्टडी टेबलचा प्रावधान आहे?",
        "लाइटिंग व्यवस्था काय आहे?",
        "काय स्टोरेज कैबिनेट आहे?",
        "काय कंप्यूटर डेस्कचा प्रावधान आहे?",
        "वेंटिलेशन सिस्टम काय आहे?",
        "काय व्हाइटबोर्डचा प्रावधान आहे?"
      ],
      "Bedroom": [
        "बेडरूमचा आकार काय आहे?",
        "काय बिल्ट-इन वार्डरोब आहे?",
        "फर्शिंग कोणत्या प्रकारची आहे?",
        "किती इलेक्ट्रिकल आउटलेट आहेत?",
        "काय किंग-साइज बेडचा प्रावधान आहे?",
        "लाइटिंग व्यवस्था काय आहे?",
        "काय ड्रेसिंग टेबल आहे?",
        "काय साइड टेबलचा प्रावधान आहे?",
        "वेंटिलेशन सिस्टम काय आहे?",
        "काय टीवीचा प्रावधान आहे?"
      ]
    }
  };

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    changePreQuestionsSet(userLanguage, navigationProp);
  }, [userLanguage, navigationProp?.currentSubItem]);

  const shuffleArray = (array) => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  const changePreQuestionsSet = (userLanguage, navigationProp) => {
    console.log("User Language:", userLanguage);
    console.log("Current SubItem:", navigationProp?.currentSubItem);

    const languageData = roomQuestionsData[userLanguage] || roomQuestionsData["en-IN"]; // Default to English
    console.log("Language Data:", languageData);

    let roomSpecificQuestions = languageData[navigationProp?.currentSubItem] || [];

    console.log("Room-specific Questions Before Filtering:", roomSpecificQuestions);

    roomSpecificQuestions = roomSpecificQuestions.filter(q => q.trim() !== "");

    console.log("Final Room-specific Questions After Filtering:", roomSpecificQuestions);

    setQuestions(shuffleArray(roomSpecificQuestions));
  };

  return (
    <ScrollContainer
      className="scroll-container"
      horizontal={true}
      style={{
        position: "absolute",
        zIndex: "2",
        transition: "transform 1s ease-out",
        maskImage: "linear-gradient(to left, transparent 0%, black 15%, black 85%, transparent 100%), linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)",
        height: "100%"
      }}
    >
      <ul className="pre-question-list">
        {questions.map((question, index) => (
          <li className='pre-ques fs-xxs ff-r' key={index} onClick={() => {
            if (!(startingToRecord || responseReady === false || isRecording || isThinking)) {
              preDefinedQuestion(question);
            }
          }}>
            {question}
          </li>
        ))}
      </ul>
    </ScrollContainer>
  );
};

export default PreQuestions;
