// App.js
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import "./App.css";
import Game from "./components/Game";
import UserAuth from "./components/Auth/UserAuth";
import AgentAuth from "./components/Auth/AgentAuth";
// import Dashboard from "./components/Assets/Dashboard";
// import Login from "./components/Assets/dashLogin";
import { LanguageProvider } from "./components/Assets/LanguageContext";
import FeedbackForm from "./components/Assets/feedbackform";

function App() {
  const url = "https://api.ninereflex.xrvizion.com";
  const secretKey = "LeapintotheOnline";

  const userName = useRef("");
  const userMobileNumber = useRef(0);
  const [formSubmitted, setIsFormSubmitted] = useState(false);

  const decodeId = (encoded) => {
    let urlUnsafeEncoded = encoded.replace(/-/g, "+").replace(/_/g, "/");
    const paddingNeeded = 4 - (urlUnsafeEncoded.length % 4);
    if (paddingNeeded && paddingNeeded !== 4) {
      urlUnsafeEncoded += "=".repeat(paddingNeeded);
    }
    const bytes = CryptoJS.AES.decrypt(urlUnsafeEncoded, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  useEffect(() => {
    const handleResize = () => {
      document.body.style.height = `${window.innerHeight}px`;
      document.body.style.width = `${window.innerWidth}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const UserRoute = () => {
    const { agentName } = useParams();
    const [agentId, setAgentId] = useState();
    const [isValid, setIsValid] = useState(null);

    useEffect(() => {
      const checkAgentIdExists = async () => {
        try {
          const response = await axios.post(
            url + "/agent/isvalidagent",
            {
              url: window.location.href.endsWith("/")
                ? window.location.href.slice(0, -1)
                : window.location.href,
            },
            { withCredentials: true }
          );
          setIsValid(response.data.isValid);
          setAgentId(response.data.agentId);
        } catch (error) {
          setIsValid(false);
        }
      };

      checkAgentIdExists();
    }, [agentName]);

    if (isValid === null) {
      return <div>Loading...</div>;
    }

    if (!isValid) {
      return <Navigate to="/" />;
    }

    return <UserAuth agentId={agentId} />;
  };

  const ProtectedRoute = ({ formSubmitted }) => {
    if (!formSubmitted) {
      return <Navigate to="/" replace />;
    }
    switch (window.location.pathname) {
      case "/walkthrough":
        return (
          <Game
            userMobileNumber={userMobileNumber}
            userName={userName}
            isAgent={false}
          />
        );
      case "/feedback":
        return <FeedbackForm />;
      default:
        return <Navigate to="/" replace />;
    }
  };

  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <UserAuth
                userName={userName}
                userMobileNumber={userMobileNumber}
                agentId={"a1"}
                formSubmitted={formSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            }
          />
          <Route
            path="/walkthrough"
            element={<ProtectedRoute formSubmitted={formSubmitted} />}
          />
          <Route
            path="/feedback"
            element={<ProtectedRoute formSubmitted={formSubmitted} />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;
